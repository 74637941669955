import React, { useEffect, useState } from 'react';
import './Stream.css';
import Card from '../../../Components/Card/Card';
import TorrentService from '../Torrent.service';
import { useDispatch, useSelector } from 'react-redux';

function TorrentStream() {

  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [type, setType] = useState('movie');
  const data = useSelector((state) => state.torrent.stream.data);
  const [url, setUrl] = useState('');

  const createStream = (ev) => {
    ev.preventDefault();
    TorrentService.createStream(dispatch, {name, type});
  };

  useEffect(() => {
    if (data) {
      setUrl(TorrentService.getStreamUrl(data));
    }
  }, [data])

  return (
    <div className="TorrentStream">
        <Card label="Stream">
          <form className='TorrentStream-form' onSubmit={createStream}>
            <div className='TorrentStream-form-type'>
              <input id="type-tv" type="radio" name="type" value="tv" onChange={(ev) => setType(ev.target.value)} />
              <label htmlFor="type-tv">Série</label>
              <input id="type-movie" type="radio" name="type" value="movie" onChange={(ev) => setType(ev.target.value)} />
              <label htmlFor="type-movie">Film</label>
            </div>
            <div className='TorrentStream-form-name'>
              <input type="text" name="name" onChange={(ev) => setName(ev.target.value)} />
            </div>
            <button type="submit">Streamer</button>
          </form>
        </Card>
        { url && <Card>
          <video controls autoplay>
            <source src={url}></source>
          </video>
        </Card> }
    </div>
  );
}

export default TorrentStream;