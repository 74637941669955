import { createRole, createRoleSuccess, createRoleLimit, createRoleLimitSuccess, getRoles, getRolesSuccess, getUsers, getUsersSuccess, updateRoleLimit, updateRoleLimitSuccess, removeRoleLimit, removeRoleLimitSuccess, updateUserRoleSuccess, updateUserRole, deleteUser, deleteUserSuccess, updateUserValidity, updateUserValiditySuccess, getTorrents, getTorrentsSuccess, updateTorrentsUserSuccess, updateTorrentsUser, removeRole, removeRoleSuccess, removeTorrent, removeTorrentSuccess } from "../features/Admin/Admin.slice";
import ApiService from "./Api.service";

export default class AdminService {
    static getRoles(dispatch) {
        dispatch(getRoles());
        ApiService.get(`/api/users/roles`).request
            .then((res) => {
                dispatch(getRolesSuccess(res.data));
            });
    }

    static createRole(dispatch, newRole) {
        dispatch(createRole())
        ApiService.post(`/api/users/roles`, newRole).request
            .then((res) => {
                dispatch(createRoleSuccess(res.data))
            })
    }

    static removeRole(dispatch, roleId) {
        dispatch(removeRole())
        ApiService.delete(`/api/users/roles/${roleId}`).request
            .then((res) => {
                dispatch(removeRoleSuccess({roleId}))
            })
    }

    static updateRoleLimit(dispatch, roleId, roleLimit) {
        dispatch(updateRoleLimit())
        ApiService.put(`/api/users/roles/${roleId}/limits`, roleLimit).request
            .then((res) => {
                dispatch(updateRoleLimitSuccess({roleId, limit: res.data}))
            })
    }

    static createRoleLimit(dispatch, roleId, newRoleLimit) {
        dispatch(createRoleLimit())
        ApiService.post(`/api/users/roles/${roleId}/limits`, newRoleLimit).request
            .then((res) => {
                dispatch(createRoleLimitSuccess({roleId, limit: res.data}))
            })
    }

    static removeRoleLimit(dispatch, roleId, limitId) {
        dispatch(removeRoleLimit())
        ApiService.delete(`/api/users/roles/${roleId}/${limitId}`).request
            .then((res) => {
                dispatch(removeRoleLimitSuccess({roleId, limitId}))
            })
    }

    static getUsers(dispatch) {
        dispatch(getUsers())
        ApiService.get(`/api/users`).request
            .then((res) => {
                dispatch(getUsersSuccess(res.data))
            });
    }

    static updateUserRole(dispatch, userId, role) {
        dispatch(updateUserRole())
        ApiService.put(`/api/users/${userId}/role`, {role}).request
            .then((res) => {
                dispatch(updateUserRoleSuccess({userId, role}))
            });
    }

    static updateUserValidity(dispatch, userId, validity) {
        dispatch(updateUserValidity())
        ApiService.put(`/api/users/${userId}/validity`, {validity}).request
            .then((res) => {
                dispatch(updateUserValiditySuccess({userId, validity}))
            });
    }

    static deleteUser(dispatch, userId) {
        dispatch(deleteUser())
        ApiService.delete(`/api/jackerta/torrent/user/${userId}`).request
            .then((res) => {
                dispatch(deleteUserSuccess({userId}))
            });
    }

    static getTorrents(dispatch) {
        dispatch(getTorrents());
        ApiService.get(`/api/jackerta/torrent`).request
            .then((res) => {
                dispatch(getTorrentsSuccess(res.data))
            });
    }
    static updateTorrentUser(dispatch, torrentId, userId) {
        dispatch(updateTorrentsUser());
        ApiService.put(`/api/jackerta/torrent/${torrentId}/update-user`, {userId}).request
            .then((res) => {
                dispatch(updateTorrentsUserSuccess(res.data))
            });
    }

    static removeTorrent(dispatch, torrentId) {
        dispatch(removeTorrent());
        ApiService.delete(`/api/jackerta/torrent/${torrentId}`).request
            .then((res) => {
                dispatch(removeTorrentSuccess({ _id: torrentId}))
            });
    }
} 