import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: {
    loading: false,
    loaded: false,
    privates: [],
    shareds: [],
  },
  trackers: {
    loading: false,
    loaded: false,
    data: []
  },
  detail: {
    loading: false,
    loaded: false,
    data: null,
    files: {
      loading: false,
      loaded: false,
      data: null
    }
  },
  stream: {
    loading: false,
    loaded: false,
    data: null,
  },
  searcher: {
    query: '',
    trackers: [],
    nbTrackersFinished: 0,
    results: []
  }
};
const sortTorrents = (torrents) => {
  torrents.sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return dateA < dateB ? 1 : dateB < dateA ? -1 : 0
  });
}


export const torrentSlice = createSlice({
  name: 'torrent',
  initialState,
  reducers: {
    reset: (state) => {
      state.list = initialState.list;
      state.trackers = initialState.trackers;
      state.detail = initialState.detail;
      state.searcher = initialState.searcher;
    },
    getList : (state) => {
      state.list.loading = true;
      state.list.loaded = false;
      state.list.privates = [];
      state.list.shareds = [];
    },
    getListSuccess : (state, {payload}) => {
      state.list.loading = false;
      state.list.loaded = true;
      const torrents = payload.torrents.filter((torrent) => !!torrent.datas);
      sortTorrents(torrents);
      const isPrivate = (torrent) => payload.userId === torrent.user._id;
      const isShared = (torrent) => payload.userId !== torrent.user._id;
      state.list.privates = torrents.filter(isPrivate);
      state.list.shareds = torrents.filter(isShared);
    },
    updateList: (state, {payload}) => {
      const updateData = (torrent) => {
        const updatedTorrent = payload.torrents.find((t) => {
          return t.hash === torrent.datas.hash
        });
        if(updatedTorrent) {
          torrent.datas = updatedTorrent;
        }
        return torrent;
      };
      state.list.privates = state.list.privates.map(updateData);
      state.list.shareds = state.list.shareds.map(updateData);
    },
    updateListBySaving: (state, {payload}) => {
      const isPrivate = payload.userId === payload.torrent.user._id;
      const torrentType = isPrivate ? 'privates' : 'shareds';
      const getTorrentById = (torrent) => torrent._id === payload.torrent._id;
      const torrentIndex = state.list[torrentType].findIndex(getTorrentById);
      if(torrentIndex > -1) {
        state.list[torrentType].splice(torrentIndex, 1, payload.torrent);
      } else {
        state.list[torrentType] = [...state.list[torrentType], payload.torrent];
        sortTorrents(state.list[torrentType]);
      }
    },
    updateListByRemoving: (state, {payload}) => {
      const removeTorrentById = (torrent) => torrent._id !== payload.torrent._id;
      state.list.privates = state.list.privates.filter(removeTorrentById);
      state.list.shareds = state.list.shareds.filter(removeTorrentById);
    },
    getTrackers: (state) => {
      state.trackers.loading = true;
      state.trackers.loaded = false;
      state.trackers.data = [];
    },
    getTrackersSuccess: (state, {payload}) => {
      state.trackers.loading = false;
      state.trackers.loaded = true;
      state.trackers.data = payload;
    },
    startSearch: (state, {payload}) => {
      state.searcher = {
        query: payload.query,
        trackers: payload.trackers,
        results: [],
        nbTrackersFinished: 0
      };
    },
    setSearchResult: (state, {payload}) => {
      if(payload.query === state.searcher.query) {
        const results = payload.results.map((result) => ({...result, id: `${payload.query}-${result.trackerName}-${result.download}`}))
        state.searcher.results = [...state.searcher.results, ...results];
        state.searcher.nbTrackersFinished++;
      }
    },
    setSearchTorrentAdded: (state, {payload}) => {
      const torrent = state.searcher.results.find((result) => result.id === `${state.searcher.query}-${payload.trackerName}-${payload.download}`);
      if(torrent) {
        torrent.added = true;
      }
    },
    updateShare: (state, {payload}) => {
      const torrent = state.list.privates.find((torrent) => torrent._id === payload.torrentId);
      torrent.shared = payload.isShare;
    },
    deleteSuccess: (state, {payload}) => {
      state.list.privates = state.list.privates.filter((torrent) => torrent._id !== payload.torrentId);
    },
    getDetail: (state) => {
      state.detail.data = null;
      state.detail.loading = true;
      state.detail.loaded = false;
    },
    getDetailSuccess: (state, {payload}) => {
      state.detail.data = payload;
      state.detail.loading = false;
      state.detail.loaded = true;
    },
    getDetailFiles: (state) => {
      state.detail.files.data = null;
      state.detail.files.loading = true;
      state.detail.files.loaded = false;
    },
    getDetailFilesSuccess: (state, {payload}) => {
      if(payload && payload.files) {
        payload.files.sort((a, b) => {
          if(a.type === 'directory') {
            return -1;
          } else if(b.type === 'directory') {
            return 1;
          } else {
            return 0;
          }
        });
      }
      state.detail.files.data = payload;
      state.detail.files.loading = false;
      state.detail.files.loaded = true;
    },
    createStream: (state) => {
      state.stream.data = null;
      state.stream.loading = true;
      state.stream.loaded = false;
    },
    createStreamSuccess: (state, {payload}) => {
      state.stream.data = payload;
      state.stream.loading = false;
      state.stream.loaded = true;
    },
    createStreamError: (state) => {
      state.stream.data = null;
      state.stream.loading = false;
      state.stream.loaded = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { reset, getList, getListSuccess, updateList, updateListBySaving, updateListByRemoving, getTrackers, getTrackersSuccess, startSearch, setSearchResult, setSearchTorrentAdded, updateShare, deleteSuccess, getDetail, getDetailSuccess, getDetailFiles, getDetailFilesSuccess, createStream, createStreamSuccess, createStreamError } = torrentSlice.actions

export default torrentSlice.reducer