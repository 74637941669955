import React from 'react';
import { useDispatch } from 'react-redux';
import convertBitToBytes from '../../../../../../../utils/convertBitToBytes.utils';
import TorrentService from '../../../../../Torrent.service';
import './ResultLine.css';
export default function ResultLine({result}) {
    const dispatch = useDispatch();
    
    const addTorrent = () => {
        TorrentService.addFromSearch(dispatch, result);
    };
    const getAddColor = () => {
        if (result.added) {
            return 'success';
        } else if (result.seeders === 0) {
            return 'warning';
        } else {
            return 'primary'
        }
    };

    return <tr className='ResultLine'>
    <td><button onClick={() => !result.added && addTorrent()} className={`${getAddColor()} sm block`}>{result.added ? 'Ajouté' : 'Ajouter'}</button></td>
    <td className='ResultLine-name'><a href={result.comment || result.link} target="_blank" rel="noopener noreferrer">{result.name}</a></td>
    <td>{convertBitToBytes(result.size)}</td>
    <td>{result.seeders}</td>
    <td>{result.leechers}</td>
    <td>{result.trackerName}</td>
</tr>
}