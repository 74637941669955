import React, { useState } from 'react';
import './Add.css';
import Card from '../../../Components/Card/Card';
import Search from './Search/Search';
import TorrentService from '../Torrent.service';
import { useDispatch } from 'react-redux';

function TorrentAdd() {

  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');

  const addFromFile = (ev) => {
    ev.preventDefault();
    TorrentService.addFromFile(dispatch, file);
  };

  const addFromUrl = (ev) => {
    ev.preventDefault();
    TorrentService.addFromUrl(dispatch, url);
  };
  
  return (
    <div className="TorrentAdd">
      <div className='TorrentAdd-forms'>
        <Card label="Fichier">
          <form className='TorrentAdd-file' onSubmit={addFromFile}>
            <div className='TorrentAdd-file-container'>
              <button type="button" className='block'>{!!file ? file.name : 'Sélectionnez un fichier'}</button>
              <input type="file" name="file" onChange={(ev) => setFile(ev.target.files[0])} />
            </div>
            <button type="submit" className=''>Ajouter</button>
          </form>
        </Card>
        <Card label="Url">
          <form className='TorrentAdd-url' onSubmit={addFromUrl}>
            <input type="url" placeholder='Magnet, fichier Torrent' value={url} onChange={(ev) => setUrl(ev.target.value)} />
            <button className=''>Ajouter</button>
          </form>
        </Card>
      </div>
      <div>
        <Search></Search>
      </div>
    </div>
  );
}

export default TorrentAdd;