import React from 'react';
import { useDispatch } from 'react-redux';
import AdminService from '../../../../services/Admin.service';
import './User.css';
export default function user(props) {
    
    const dispatch = useDispatch();

    const getCurrentRoleId = () => {
        const currentRole = props.roles.find((role) => role._id === (props.user.role && props.user.role._id));
        return currentRole && currentRole._id;
    };
    
    const updateRole = (ev) => {
        AdminService.updateUserRole(dispatch, props.user._id, ev.target.value);
    };

    const updateValidityFromMonth = (ev) => {
        const value = ev.target.value;
        const validity = new Date(props.user.validity);
        validity.setFullYear(validity.getFullYear(), value, 0);
        AdminService.updateUserValidity(dispatch, props.user._id, validity.toISOString());
    };

    const updateValidityFromYear = (ev) => {
        const value = ev.target.value;
        const validity = new Date(props.user.validity);
        validity.setYear(value);
        AdminService.updateUserValidity(dispatch, props.user._id, validity.toISOString());
    };

    const deleteUser = () => {
        AdminService.deleteUser(dispatch, props.user._id);
    }
    return <tr className='User'>
        <td>{props.user.name}</td>
        <td>{props.user.email}</td>
        <td><select className='sm' onChange={updateRole} defaultValue={getCurrentRoleId()}>
            <option key={undefined} value={undefined}>Aucun role</option>
            {props.roles.map((role) => <option key={role._id} value={role._id}>{role.name}</option>)}
        </select></td>
        <td className='User-validity'>
            <input type="number" value={new Date(props.user.validity).getMonth() + 1} onChange={updateValidityFromMonth} />
            <input type="number" value={new Date(props.user.validity).getFullYear()} onChange={updateValidityFromYear} />
        </td>
        <td><button className='danger sm block' onClick={deleteUser}>Supprimer</button></td>
    </tr>
}