import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Account.css';
import paypal from '../../assets/images/paypal.png';
import AccountInfoItem from './AccountInfoItem/AccountInfoItem';
import Progress from '../../Components/Progress/Progress';
import UserService from '../../services/User.service';
import convertBitToBytes, { convertBitToGigaBytes } from '../../utils/convertBitToBytes.utils';
import Card from '../../Components/Card/Card';
import EnvironementService from '../../services/Environment.service';

function Account() {

  const dispatch = useDispatch();

  const info = useSelector((state) => state.user.info);
  const torrent = useSelector((state) => state.user.torrent);
  const rss = useSelector((state) => state.user.rss);

  const [password, setPassword] = useState({oldPassword: '', newPassword: '', newPasswordConfirmation: '', isSame: false, isSubmitted: false});
  const [newRssFlow, setNewRssFlow] = useState('');

  const getDownloadedValue = () => {
    const downloaded = info.datas && info.datas.find((data) => data.type === 'downloaded');
    return downloaded ? downloaded.value : 0;
  };

  const getUploadedValue = () => {
    const uploaded = info.datas && info.datas.find((data) => data.type === 'uploaded');
    return uploaded ? uploaded.value : 0;
  };

  const getLimitSpaceValue = () => {
    const uploaded = info.role.limits.find((limit) => limit.type === 'space');
    return uploaded.value;
  };

  const getLimitQuantityValue = () => {
    const uploaded = info.role.limits.find((limit) => limit.type === 'quantity');
    return uploaded.value;
  };

  const getRatioValue = () => (Math.round(getUploadedValue() / getDownloadedValue() * 100) / 100) || 0;

  const getAutoShareValue = () => {
    const share = info.datas && info.datas.find((data) => data.type === 'share');
    return share ? share.value : 0;
  };
  
  const toggleAutoShare = (ev) => {
    UserService.toggleShareTorrent(dispatch, ev.target.checked);
  };

  const changeOldPasswordHandler = (ev) => {
    setPassword({...password, oldPassword: ev.target.value, isSubmitted: false});
  };

  const changeNewPasswordHandler = (ev) => {
    setPassword({...password, newPassword: ev.target.value, isSubmitted: false});
  };

  const changeNewPasswordConfirmationHandler = (ev) => {
    setPassword({...password, newPasswordConfirmation: ev.target.value, isSubmitted: false});
  };

  const changePassword = (ev) => {
    ev.preventDefault();
    setPassword({...password, isSame: password.newPassword === password.newPasswordConfirmation, isSubmitted: true});
    UserService.changePassword(info._id, password);
  };

  const createRssFlow = (ev) => {
    ev.preventDefault();
    UserService.createRssFlow(dispatch, newRssFlow);
  };

  const emptyRssFeeds = (flowId) => {
    UserService.emptyRssFlow(dispatch, flowId);
  };

  const deleteRssFlow = (flowId) => {
    UserService.deleteRssFlow(dispatch, flowId);
  };

  const removeRssFlowFeed = (flowId, feedId) => {
    UserService.removeRssFlowFeed(dispatch, flowId, feedId);
  };

  return info && (
    <div className="Account">
      <Card label="Informations">
        <div className="Account-info">
          <AccountInfoItem label="Nom" value={info.name} />
          <AccountInfoItem label="Email" value={info.email} />
          <AccountInfoItem label="Role du compte" value={info.role.name} />
          <AccountInfoItem label="Date de validité" value={new Date(info.validity).toDateString()} />
          <AccountInfoItem label="Abonnement mensuel" value={info.role.price + '€'}>
            <a className="Account-info-pay" href={"https://www.paypal.com/paypalme/gaeldupont44/"+ info.role.price}><img src={paypal} alt="paypal" /></a>
          </AccountInfoItem>
        </div>
      </Card>
      <Card label="Torrents">
        <div className="Account-torrent">
          <Progress isReversed={true} label="Espace de stockage" value={convertBitToBytes(torrent.space)} percent={Math.round(convertBitToGigaBytes(torrent.space) / getLimitSpaceValue() * 100 * 100) / 100} max={getLimitSpaceValue() + 'GB'}></Progress>
          <Progress isReversed={true} label="Quantité" value={torrent.quantity} percent={torrent.quantity / getLimitQuantityValue() * 100} max={getLimitQuantityValue()}></Progress>
          <AccountInfoItem label="Total téléchargé" value={convertBitToBytes(getDownloadedValue())} />
          <AccountInfoItem label="Total envoyé" value={convertBitToBytes(getUploadedValue())} />
          <AccountInfoItem label="Ratio" value={getRatioValue()} />
          <AccountInfoItem label="Partage automatique" value="Partager automatiquement les torrents lorsqu'ils sont ajoutés">
            <input type="checkbox" checked={getAutoShareValue()} onChange={toggleAutoShare}/>
          </AccountInfoItem>
        </div>
      </Card>
      <Card label="Changement de mot de passe">
        <div className="Account-change-password">
          <form onSubmit={changePassword}>
            <input type="password" placeholder="Ancien mot de passe" onChange={changeOldPasswordHandler} />
            <input type="password" placeholder="Nouveau mot de passe" onChange={changeNewPasswordHandler} />
            <input type="password" placeholder="Confirmation du nouveau mot de passe" onChange={changeNewPasswordConfirmationHandler} />
            {password.isSubmitted && !password.isSame && <p className="error">Le mot de passe de confirmation ne correspond pas au nouveau mot de passe.</p> }
            <button type="submit">Modifier</button>
          </form>
        </div>
      </Card>
      <Card label="Flux RSS">
        <div className="Account-rss">
          <form className="Account-rss-create" onSubmit={createRssFlow}>
            <input type="text" placeholder="Nom du nouveau flux RSS" onChange={(ev) => setNewRssFlow(ev.target.value)} />
            <button type="submit">Créer</button>
          </form>
          {rss && !!rss.length && <div className='Account-rss-flows'>
            {rss.map((flow) => <div key={flow._id} className={`Account-rss-flow`}>
              <a className='Account-rss-flow-name' href={`${EnvironementService.config.origin}/api/rss/${flow._id}`} target='_blank' rel="noopener noreferrer">{flow.name}</a>
              {flow.feeds && flow.feeds.map((feed) => <div key={feed._id} className='Account-rss-flow-feed'>
                <a href={`${EnvironementService.config.origin}${feed.url}`}>{feed.name}</a>
                <button className='danger sm' onClick={() => removeRssFlowFeed(flow._id, feed._id)}>Supprimer</button>
              </div>)}
              {(!flow.feeds || flow.feeds.length === 0) && <div className='Account-rss-flow-no-feed'>Vous n'avez aucun contenu dans ce flux !</div>}
              <div className='Account-rss-flow-actions'>
                {rss.feeds && rss.feeds.length > 0 && <button className='block warning' onClick={() => emptyRssFeeds(flow._id)}>Vider</button>}
                <button className='block danger' onClick={() => deleteRssFlow(flow._id)}>Supprimer</button>
              </div>
            </div>)}
          </div>}
        </div>
      </Card>
    </div>
  );
}

export default Account;
