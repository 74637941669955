import React, { useEffect } from 'react';
import { useAuthContext } from '../context/AuthContext';
import {Navigate, useNavigate} from 'react-router-dom';
import { isTokenExpired } from '../services/Token.service';
function NotAuthGuard({ children }){
    
    const navigate = useNavigate();
    const auth = useAuthContext();

    useEffect(() => {
        if(auth.token && isTokenExpired(auth.token)) {
            auth.disconnect();
            navigate('/connexion');
        }
    }, []);

    if (auth.token && !isTokenExpired(auth.token)) {
        return <Navigate to='/torrent/prives' />
    }
    return children;
}

export default NotAuthGuard;