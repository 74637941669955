import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import logo from '../assets/images/logo.svg';
import {ReactComponent as ReactLogo}  from '../assets/images/menu.svg';
import { useAuthContext } from '../context/AuthContext';
import useGuard from '../hooks/useGuard';
import useResponsive from '../hooks/useResponsive';
export default function Header() {

    
    const auth = useAuthContext();
    const guard = useGuard();

    const [isDarkMode, setIsDarkMode] = useState(JSON.parse(localStorage.getItem('dark-mode')) || false);
    const {breakpoint} = useResponsive();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const logout = (ev) => {
      ev.preventDefault();
      setIsMenuOpen(false);
      auth.disconnect();
    };

    const updateDarkMode = () => {
        localStorage.setItem('dark-mode', !isDarkMode);
        setIsDarkMode(!isDarkMode);
    };    

    
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    return <header className="Header">
      <div className={`${breakpoint !== 'xl' ? 'Header-menu-container' : ''}`}>
        <img onClick={() => updateDarkMode()} src={logo} className="Header-logo" alt="logo" />
        <NavLink className="Header-home" to="/" rel="noopener noreferrer">Jackerta</NavLink>
        <div className={`Header-menu-backdrop ${isMenuOpen ? 'Header-menu-backdrop--visible' : ''}`} onClick={() => setIsMenuOpen(false)}></div>
        {!!auth.token ? <div className={`Header-auth ${breakpoint !== 'xl' ? 'Header-menu-panel' : ''}  ${isMenuOpen ? 'Header-menu-panel--open' : ''}`}>
            <ul className="Header-auth-links">
            <li><NavLink className={(navData) => (navData.isActive ? "active" : '')} to="/torrent/prives" onClick={() => setIsMenuOpen(false)}>Mes torrents</NavLink></li>
            <li><NavLink className={(navData) => (navData.isActive ? "active" : '')} to="/torrent/partages" onClick={() => setIsMenuOpen(false)}>Torrents partagés</NavLink></li>
            <li><NavLink className={(navData) => (navData.isActive ? "active" : '')} to="/torrent/ajout" onClick={() => setIsMenuOpen(false)}>Ajouter un torrent</NavLink></li>
            {guard.hasRoleLevelGuard(2) && <li><NavLink className={(navData) => (navData.isActive ? "active" : '')} to="/torrent/stream" onClick={() => setIsMenuOpen(false)}>Streaming</NavLink></li>}
            {guard.hasRoleLevelGuard(2) && <li><NavLink className={(navData) => (navData.isActive ? "active" : '')} to="/administration" onClick={() => setIsMenuOpen(false)}>Administration</NavLink></li>}
            </ul>
            <ul className="Header-auth-user">
            <li><NavLink className={(navData) => (navData.isActive ? "active" : '')} to="/compte" onClick={() => setIsMenuOpen(false)}>Mon compte</NavLink></li>
            <li><a href={'/'} onClick={logout}>Se déconnecter</a></li>
            </ul>
        </div> : 
        <div className={`Header-not-auth ${breakpoint !== 'xl' ? 'Header-menu-panel' : ''} ${isMenuOpen ? 'Header-menu-panel--open' : ''}`}>
            <ul className="Header-not-auth-links">
            <li><NavLink className={(navData) => (navData.isActive ? "active" : '')} to="/connexion" onClick={() => setIsMenuOpen(false)}>Se connecter</NavLink></li>
            <li><NavLink className={(navData) => (navData.isActive ? "active" : '')} to="/inscription" onClick={() => setIsMenuOpen(false)}>S'inscrire</NavLink></li>
            </ul>
        </div>
        }
        <div className={`Header-menu-icon ${breakpoint !== 'xl' ? 'Header-menu-icon--visible' : ''}`} onClick={() => setIsMenuOpen(!isMenuOpen)}><ReactLogo /></div>
    </div>
    
  </header>
}