import io from "socket.io-client";
import EnvironementService from "./Environment.service";
import { getToken } from "./Token.service";
export const socket = io(EnvironementService.config.origin, {
  query: {
    token: getToken(),
  },
  path: '/ws',
});
socket.on('connect', () => {
  console.log('WS - Connected to server');
});