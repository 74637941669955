import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Connection from './features/Connection/Connection';
import { AuthProvider } from './context/AuthContext';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Account from './features/Account/Account';
import TorrentPrivate from './features/Torrent/Private/Private';
import AuthGuard from './guards/AuthGuard';
import NotAuthGuard from './guards/NotAuthGuard';
import { Provider } from 'react-redux'
import { store } from './store';
import TorrentAdd from './features/Torrent/Add/Add';
import Admin from './features/Admin/Admin';
import HasRoleLevelGuard from './guards/HasRoleLevelGuard';
import TorrentDetail from './features/Torrent/Detail/Detail';
import TorrentShared from './features/Torrent/Shared/Shared';
import Register from './features/Register/Register';
import TorrentStream from './features/Torrent/Stream/Stream';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <NotAuthGuard><AuthGuard></AuthGuard></NotAuthGuard>
      },
      {
        path: "inscription",
        element: <NotAuthGuard><Register /></NotAuthGuard>
      },
      {
        path: "connexion",
        element: <NotAuthGuard><Connection /></NotAuthGuard>
      },
      {
        path: "torrent/ajout",
        element: <AuthGuard><TorrentAdd /></AuthGuard>
      },
      {
        path: "torrent/prives",
        element: <AuthGuard><TorrentPrivate /></AuthGuard>
      },
      {
        path: "torrent/partages",
        element: <AuthGuard><TorrentShared /></AuthGuard>
      },
      {
        path: "torrent/stream",
        element: <AuthGuard><HasRoleLevelGuard level="2"><TorrentStream /></HasRoleLevelGuard></AuthGuard>
      },
      {
        path: "torrent/:id",
        element: <AuthGuard><TorrentDetail /></AuthGuard>
      },
      {
        path: "compte",
        element: <AuthGuard><Account /></AuthGuard>
      },
      {
        path: "administration",
        element: <AuthGuard><HasRoleLevelGuard level="2"><Admin /></HasRoleLevelGuard></AuthGuard>
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </Provider>    
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
